import React, { FC, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Container,
} from '@material-ui/core';
import Helmet from 'react-helmet';
import clsx from 'clsx';

import colors from '../../themes/main-colors';
import PageTitle from '../../components/blog-components/page-title';
import ArticleCard from '../../components/blog-components/article-card';
import { useIsMobile } from '../../util/style-utils';
import { useTranslation } from '../../hooks/useTranslation';
import Categories from '../../components/blog-components/categories';
import { BlogArticleFragment } from '../../__generated__/gatsby-types';

export const blogHomePageQuery = graphql`
  {
    allPosts: allContentfulArticle(
      filter: {
        node_locale: { eq: "en" }
        slug: { ne: null, regex: "/^((?!placeholder).)*$/i" }
      }
      sort: { fields: [date], order: DESC }
    ) {
      totalCount
      nodes {
        ...BlogArticle
      }
    }

    blogHomeMainFeature: allContentfulArticle(
      filter: {
        blogHomeMainFeature: { eq: true }
        node_locale: { eq: "en" }
        slug: { ne: null, regex: "/^((?!placeholder).)*$/i" }
      }
      sort: { fields: [date], order: DESC }
      limit: 1
    ) {
      totalCount
      nodes {
        ...BlogArticle
      }
    }

    feature1: allContentfulArticle(
      filter: {
        featured: { eq: true }
        node_locale: { eq: "en" }
        slug: { ne: null, regex: "/^((?!placeholder).)*$/i" }
      }
      sort: { fields: [date], order: DESC }
      limit: 1
    ) {
      totalCount
      nodes {
        ...BlogArticle
      }
    }

    feature2: allContentfulArticle(
      filter: {
        featured2: { eq: true }
        node_locale: { eq: "en" }
        slug: { ne: null, regex: "/^((?!placeholder).)*$/i" }
      }
      sort: { fields: [date], order: DESC }
      limit: 1
    ) {
      totalCount
      nodes {
        ...BlogArticle
      }
    }

    feature3: allContentfulArticle(
      filter: {
        featured3: { eq: true }
        node_locale: { eq: "en" }
        slug: { ne: null, regex: "/^((?!placeholder).)*$/i" }
      }
      sort: { fields: [date], order: DESC }
      limit: 1
    ) {
      totalCount
      nodes {
        ...BlogArticle
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },
    articleCardWrapper: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'space-between',
      overflow: 'hidden',
      '& > *:nth-child(3n)': {
        marginRight: -25,
        [theme.breakpoints.down('sm')]: {
          marginRight: 0,
        },
      },
    },
    itemWrapper: {
      paddingRight: 25,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
    },
    buttonLoadMore: {
      display: 'flex',
      background: colors.orange,
      margin: '60px auto',
      '&:hover': {
        background: colors.orange,
      },
      [theme.breakpoints.down('sm')]: {
        margin: '30px auto',
      },
    },
    rowWrapper: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        display: 'initial',
      },
    },
    rowWrapperItem: {
      marginRight: 40,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
    },
    articleCardItem: {
      paddingRight: 40,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
    },
    normalSpacing: {
      justifyContent: 'normal',
    },
  }),
);

const Blog: FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const {
    allPosts,
    blogHomeMainFeature,
    feature1,
    feature2,
    feature3,
  } = useStaticQuery(blogHomePageQuery);

  const postsPerPage: number = 6;
  const [nextPost, setNextPost] = useState<number>(postsPerPage);

  const [mainCard, setMainCard] = useState<BlogArticleFragment | undefined>();
  const [sideCards, setSideCards] = useState<BlogArticleFragment[]>([]);

  const [recentPosts, setRecentPosts] = useState<BlogArticleFragment[]>([]);
  const [recentPostsToShow, setRecentPostsToShow] = useState<
    BlogArticleFragment[]
  >([]);

  const [isShowMoreButtonDisplayed, setIsShowMoreButtonDisplayed] = useState<
    Boolean
  >(false);

  useEffect(() => {
    if (allPosts && allPosts?.nodes.length > 0) {
      const mainPost: BlogArticleFragment = blogHomeMainFeature?.nodes[0];
      setMainCard(mainPost);

      let sideCardArray: BlogArticleFragment[] = [
        feature1?.nodes[0],
        feature2?.nodes[0],
        feature3?.nodes[0],
      ].filter(post => post !== undefined);

      const excludeFromRecentPosts = [mainPost, ...sideCardArray]?.map(
        (post: BlogArticleFragment) => post?.contentful_id,
      );

      let recentPostsArray: BlogArticleFragment[] = allPosts?.nodes.filter(
        (val: BlogArticleFragment) =>
          !excludeFromRecentPosts?.includes(val?.contentful_id),
      );

      const sideCardsToShow: number = 3;
      const sideCardArrayLength = sideCardArray.length;
      if (sideCardArrayLength !== sideCardsToShow) {
        sideCardArray = [
          ...sideCardArray,
          ...recentPostsArray.slice(0, sideCardsToShow - sideCardArrayLength),
        ];

        recentPostsArray = [
          ...recentPostsArray.slice(sideCardsToShow - sideCardArrayLength),
        ];
      }
      setSideCards(sideCardArray);
      setRecentPosts(recentPostsArray);
      setRecentPostsToShow(recentPostsArray.slice(0, nextPost));
      setIsShowMoreButtonDisplayed(nextPost < recentPostsArray.length);
    }
  }, [allPosts]);

  const handleLoadMorePosts = () => {
    const newPostIndex = nextPost + postsPerPage;
    setRecentPostsToShow(recentPosts.slice(0, newPostIndex));
    setNextPost(newPostIndex);
    setIsShowMoreButtonDisplayed(newPostIndex < recentPosts.length);
  };

  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>{t('BLOG_HOME_TITLE_SEO')}</title>
        <meta name="description" content={t('BLOG_HOME_DESCRIPTION_SEO')} />
      </Helmet>
      <Container style={{ paddingRight: 0, paddingLeft: 0 }}>
        <Categories />
      </Container>
      <Container>
        <div className={classes.root}>
          <PageTitle title={t('BLOG_HOME')} subHeading />
          <Grid item xs={12} sm={12} md={12} className={classes.rowWrapper}>
            {mainCard ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                className={classes.rowWrapperItem}
              >
                <Box>
                  <ArticleCard
                    image={'bg'}
                    item={mainCard}
                    categoryName
                    style={{ height: 432 }}
                  />
                </Box>
              </Grid>
            ) : null}

            {sideCards.length > 0 ? (
              <Grid item xs={12} sm={12} md={4}>
                {sideCards?.map((item: BlogArticleFragment) => (
                  <ArticleCard
                    key={item.id}
                    image={'left'}
                    item={item}
                    categoryName
                  />
                ))}
              </Grid>
            ) : null}
          </Grid>

          {recentPostsToShow.length ? (
            <>
              <PageTitle
                title={t('MOST_RECENT_POSTS')}
                subHeading
                style={{ marginTop: isMobile ? 20 : 60 }}
              />
              <Box
                className={clsx(
                  classes.articleCardWrapper,
                  recentPostsToShow.length % 3 !== 0
                    ? classes.normalSpacing
                    : '',
                )}
              >
                {recentPostsToShow.map(
                  (item: BlogArticleFragment, index: number) => (
                    <Grid
                      item
                      sm={12}
                      md={4}
                      className={classes.itemWrapper}
                      key={item.id}
                    >
                      {item.promoCard ? (
                        <ArticleCard
                          key={index}
                          image={'bg'}
                          variant
                          item={item}
                          style={{ maxWidth: '100%' }}
                        />
                      ) : (
                        <ArticleCard
                          key={index}
                          image={'top'}
                          categoryName
                          item={item}
                          authorName
                          articleDate
                          style={{ maxWidth: '100%' }}
                        />
                      )}
                    </Grid>
                  ),
                )}
              </Box>
            </>
          ) : null}

          {isShowMoreButtonDisplayed ? (
            <Button
              variant={'contained'}
              color={'primary'}
              className={classes.buttonLoadMore}
              onClick={handleLoadMorePosts}
            >
              {t('LOAD_MORE')}
            </Button>
          ) : null}
        </div>
      </Container>
    </>
  );
};

export default Blog;
